import { Fragment, useState } from "react"
import styled from "styled-components"

import { Tooltip } from "@material-ui/core"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import {
  useDeleteHomeGroupHome,
  usePostHomeGroupHomes,
} from "src/data/homeGroups/queries/homeGroupHomeQueries"
import { useFetchHomeGroup } from "src/data/homeGroups/queries/homeGroupQueries"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { DEFAULT_HOME_NAME, THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { minimumGray } from "src/ui/colors"
import { GridTable } from "src/ui/GridTable/GridTable"
import InfoIcon from "src/ui/icons/i-icon.svg"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeGroupAddHomes({ homeGroupId }: { homeGroupId: string }) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const { limit, offset, setOffset } = useUrlPager()
  const { org } = useOrganization()
  const [searchString, setSearchString] = useState("")
  const [busyHomeId, setBusyHomeId] = useState("")
  const postHomeGroupHomes = usePostHomeGroupHomes()
  const deleteHomeGroupHome = useDeleteHomeGroupHome()
  const fetchHomeGroup = useFetchHomeGroup({
    orgId: org.id,
    homeGroupId: homeGroupId,
  })
  const fetchHomes = useFetchHomes({
    orgId: org.id,

    filters: { limit, offset, name: searchString },
  })

  const isLoading = fetchHomeGroup.isInitialLoading || fetchHomes.isLoading

  const orgHomes = fetchHomes.data?.homes || []

  const isError = postHomeGroupHomes.isError || deleteHomeGroupHome.isError

  function handleAddHomeClick(home_id: string) {
    setBusyHomeId(home_id)
    postHomeGroupHomes.mutate(
      {
        organizationId: org.id,
        homeGroupId,
        body: { home_id },
      },
      {
        onSettled() {
          setBusyHomeId("")
        },
      }
    )
  }

  function handleRemoveHomeClick(home_id: string) {
    setBusyHomeId(home_id)
    deleteHomeGroupHome.mutate(
      { orgId: org.id, homeGroupId, home_id },
      {
        onSettled() {
          setBusyHomeId("")
        },
      }
    )
  }

  const rows: JSX.Element[] = orgHomes.map((home) => (
    <Fragment key={home.home_id}>
      <MText variant="subtitle">{home.name}</MText>

      <ShowAddHomeAction
        key={home.home_id}
        home={home}
        currentHomeGroupId={homeGroupId}
        onAddHome={handleAddHomeClick}
        onRemoveHome={handleRemoveHomeClick}
        loading={busyHomeId === home.home_id}
      />
    </Fragment>
  ))

  if (isLoading) {
    return (
      <LoadingScreen debugInfo="Fetching homes and current homegroup details" />
    )
  }

  return (
    <AddHomeBox>
      <MText variant="heading2" marginBottom={spacing.XL}>
        {t(langKeys.organizations_homegroup_add_homes_title, {
          name: `'${fetchHomeGroup.data?.name}'`,
        })}
      </MText>
      <SearchWrapper>
        <SearchFilter
          initialValue={searchString}
          placeholder={t(langKeys.search)}
          onChange={(e) => setSearchString(e)}
        />
      </SearchWrapper>
      <GridTable
        rows={rows}
        templateColumns="2fr auto"
        useFallbackResponsiveMode
        loading={isLoading}
      />
      <PagerWrapper>
        <Pager
          limit={limit ?? HOME_LIST_LIMIT}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchHomes.data?.paging?.total_count || 0}
        />
      </PagerWrapper>

      {isError && (
        <AlertBox type="error">
          {t(langKeys.failed_something_went_wrong)}
        </AlertBox>
      )}

      <ActionArea $addTopMargin={rows.length <= limit}>
        <MButtonLegacy
          onClick={() => navigate(Routes.HomeGroupDetails.path(homeGroupId))}
        >
          {t(langKeys.done)}
        </MButtonLegacy>
      </ActionArea>
    </AddHomeBox>
  )
}

function ShowAddHomeAction({
  home,
  currentHomeGroupId,
  onAddHome,
  onRemoveHome,
  loading,
}: {
  home: THome
  currentHomeGroupId: string
  onAddHome: (homeId: string, homeName: string) => void
  onRemoveHome: (homeId: string) => void
  loading?: boolean
}) {
  const { t, langKeys } = useTranslate()

  const isAlreadyInHomeGroup =
    home.homegroup_id && home.homegroup_id !== currentHomeGroupId

  if (isAlreadyInHomeGroup) {
    return (
      <StyledInfoBox>
        <div>
          {t(langKeys.organizations_homegroup_add_homes_already_added, {})}{" "}
          <Tooltip
            placement="top"
            title={t(langKeys.organizations_home_groups_add_home_tooltip_text)}
          >
            <InfoIcon width={24} />
          </Tooltip>
        </div>
      </StyledInfoBox>
    )
  }

  const homeInCurrentHomeGroup =
    home.homegroup_id && home.homegroup_id === currentHomeGroupId

  if (homeInCurrentHomeGroup) {
    return (
      <div key={home.home_id}>
        <StyledButtonBox>
          <MButtonLegacy
            size="small"
            loading={loading}
            onClick={() => onRemoveHome(home.home_id)}
            color="secondary"
          >
            {t(langKeys.remove)}
          </MButtonLegacy>
        </StyledButtonBox>
      </div>
    )
  }

  return (
    <div key={home.home_id}>
      <StyledButtonBox>
        <MButtonLegacy
          size="small"
          variant="outlined"
          loading={loading}
          onClick={() =>
            onAddHome(
              home.home_id,
              home.name || DEFAULT_HOME_NAME(home.home_id)
            )
          }
        >
          {t(langKeys.add)}
        </MButtonLegacy>
      </StyledButtonBox>
    </div>
  )
}

const AddHomeBox = styled.div`
  width: 70ch;
`

const ActionArea = styled.div<{ $addTopMargin: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(props) => (props.$addTopMargin ? spacing.XL : 0)};
`

const SearchWrapper = styled.div`
  margin-bottom: ${spacing.L};
`

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledInfoBox = styled.div`
  color: ${minimumGray};
`

const AlertBox = styled(MBanner)`
  margin-top: ${spacing.L};
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`
